@import url("https://fonts.googleapis.com/css2?family=Onest:wght@400;500;600&display=swap");

code {
  font-family: "Onest", sans-serif, monospace;
}

/* for make full width of react date picker */
.react-datepicker-wrapper {
  width: 100% !important;
}

/* React otp field */
.MuiOutlinedInput-notchedOutline {
  border-radius: 12px !important;
}
